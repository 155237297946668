<template>
  <div v-if="hasLimits == 0" class="meterial_detail">
    <div>
      <!-- 视频详情 -->
      <div
        class="video_detail"
        v-if="$route.query.videoOrimg == 'video' ? true : false"
      >
        <el-row type="flex" class="top_title">
          <el-col :span="16" style="margin-right: 69px;">
            <span>{{ videoAndImgData.name }}</span>
            <p>{{ videoAndImgData.introduce }}</p>
          </el-col>
        </el-row>
        <el-row type="flex" class="material_content">
          <el-col :span="16" >
            <div class="video_box">
              <Xgplayer
                id="materialDetail"
                :config="playerConfig"
                @player="playInit($event)"
                class="xgplayer-is-fullscreen"
              />
              <div class="hover_effect">
                <span class="iconfont icon-fenxiang" @click="copyPath"></span>
                <span
                  class="iconfont icon-xiazai"
                  @click="videoDownloadConfirm"
                ></span>
                <span
                  @click="addWatchList()"
                  class="iconfont icon-tianjiazhiguankanliebiao"
                ></span>
                <span
                  class="iconfont icon-weishoucang"
                  @click="addCollet"
                  v-if="videoAndImgData.collect_if == 0 ? true : false"
                ></span>
                <span
                  class="iconfont icon-shoucang"
                  @click="addCollet"
                  v-else
                ></span>
                <div
                  class="existed_tag"
                  style="top: -37px; left: -28%; letter-spacing: 2px"
                  v-if="ifcopy"
                >
                  已复制链接
                </div>
              </div>
            </div>
          </el-col>
          <!-- 基本信息跟AI智能识别 -->
          <el-col :span="8" style="padding-left: 45px">
            <div class="content_box">
              <!-- 下拉框 -->
              <el-dropdown
                trigger="click"
                placement="bottom-start"
                @command="handleCommand"
              >
                <span class="el-dropdown-link">
                  {{ selectTitle}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="material_dropdown">
                  <el-dropdown-item command="a" :class="switchContent ? 'dropdown_active' : ''">
                    基本信息
                  </el-dropdown-item>
                  <el-dropdown-item command="b" :class="switchContent ? '' : 'dropdown_active'">AI智能识别</el-dropdown-item >
                </el-dropdown-menu>
              </el-dropdown>
               <!-- 添加新标签 -->
                <div v-if="selectTitle =='AI智能识别' && allowEdit== 1 " style="display: flex">
                  <!-- 输入框 -->
                  <el-input
                    v-focus
                    class="input-new-tag"
                    v-if="keyWordsVisible"
                    v-model="keyWordsValue"
                    ref="saveTagInput"
                    size="small"
                      @keyup.enter.native="handleInputConfirm()"
                  >
                  </el-input>
                  <!-- 对号跟错号 -->
                  <div class="operation" v-if="keyWordsVisible">
                    <i
                      class="iconfont icon-duihao"
                      @click="handleInputConfirm()"
                    ></i>
                    <i class="iconfont icon-cuo" @click="clearInput"></i>
                  </div>
                  <!-- 添加新标签 -->
                  <el-dropdown
                    placement="bottom-start"
                    trigger="click"
                    @command="addNewLabel"
                    @visible-change="showorhidden"
                    v-else
                  >
                    <el-button
                      class="button-new-tag"
                      size="small"
                      type="primary"
                      :style="{background:bgcColor}"
                    >
                      <i  class="iconfont icon-jiahao1"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown" class="add_dropdown">
                      <el-dropdown-item command="1">添加标签</el-dropdown-item>
                      <el-dropdown-item command="2"
                        >添加关键词</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div
                    class="existed_tag category_tag"
                    v-if="keyWordsExistedTag"
                  >
                    {{ tip }}
                  </div>
                </div>

            </div>
             <!-- 基本信息 -->
              <div class="base_info" v-if="switchContent">
                <p class="row_info">
                  <span>编号：</span>
                  <span>{{ videoAndImgData.ip_id | editId }}</span>
                </p>
                <p
                  class="row_info"
                  style="display: flex; align-items: baseline"
                >
                  <span style="flex-shrink: 0">商用权限：</span>
                  <span style="line-height: 20px">{{
                    videoAndImgData.commercial_licenses
                  }}</span>
                </p>
                <p class="row_info">
                  <span>作者/来源：</span>
                  <span>{{ videoAndImgData.uploader }}</span>
                </p>
                <p class="row_info">
                  <span>时长：</span>
                  <span>{{ videoAndImgData.duration }}</span>
                </p>
                <p class="row_info">
                  <span>分辨率：</span>
                  <span>{{ videoAndImgData.resolution }}</span>
                </p>
                <p class="row_info">
                  <span>帧率：</span>
                  <span>{{ videoAndImgData.frame }}</span>
                </p>
                <p class="row_info">
                  <span>编码：</span>
                  <span>{{ videoAndImgData.coding }}</span>
                </p>
                <p class="row_info">
                  <span>文件类型：</span>
                  <span>{{ videoAndImgData.format }}</span>
                </p>
                <p class="row_info">
                  <span>文件大小：</span>
                  <span>{{ videoAndImgData.size | transformByte }}</span>
                </p>
                <p class="row_info">
                  <span>创建时间：</span>
                  <span>{{ videoAndImgData.upload_time | formatTime }} </span>
                </p>
              </div>
              <!-- ai智能识别 -->
              <div class="ai_intelligence" v-else>
                <!-- 标签组，关键词 -->
                <div>
                   <!-- 标签组 -->
                  <div
                    class="row_line"
                    v-for="(item, index) in labelGroupData"
                    :key="index"
                  >
                    <span v-show="!(item.labelData.length==0)" class="category">{{ item.labelGroupName }}</span>
                    <div class="ai_tag">
                      <el-tag
                        :key="tag.id"
                        v-for="tag in item.labelData"
                        :closable="allowEdit?true:false"
                        :disable-transitions="false"
                        @close="handleClose(tag.tag_id, 1)"
                      >
                        {{ tag.tag_name }}
                      </el-tag>
                    </div>
                  </div>
                    <!-- 关键词 -->
                  <div class="row_line">
                    <span v-show="!(keyWordsTags.length==0)" class="category">关键词</span>
                    <div class="ai_tag">
                      <el-tag
                        :key="tag.id"
                        v-for="tag in keyWordsTags"
                        :closable="allowEdit?true:false"
                        :disable-transitions="false"
                        @close="handleClose(tag.id, 2)"
                      >
                        {{ tag.keyword }}
                      </el-tag>
                    </div>
                  </div>
                </div>

              </div>
          </el-col>
        </el-row>
      </div>
      <!-- 图片详情 -->
      <div v-else class="img_detail">
        <el-row type="flex" class="top_title">
          <el-col :span="16" style="margin-right: 69px;">
            <span>{{ videoAndImgData.name }}</span>
            <p>{{ videoAndImgData.introduce }}</p>
          </el-col>
        </el-row>
        <el-row type="flex" class="material_content">
          <el-col :span="16" >
            <div class="video_box">
              <img
                :src="videoAndImgData.url"
                alt=""
                style="max-width: 100%; max-height: 100%;flex-shrink: 0;"
              />
              <div class="hover_effect">
                <span class="iconfont icon-fenxiang" @click="copyPath"></span>
                <span
                  class="iconfont icon-xiazai"
                  @click="videoDownloadConfirm"
                ></span>
                <span
                  class="iconfont icon-weishoucang"
                  @click="addCollet"
                  v-if="videoAndImgData.collect_if == 0 ? true : false"
                ></span>
                <span
                  class="iconfont icon-shoucang"
                  @click="addCollet"
                  v-else
                ></span>
                <div
                  class="existed_tag"
                  style="top: -37px; left: -28%; letter-spacing: 2px"
                  v-if="ifcopy"
                >
                  已复制链接
                </div>
              </div>
            </div>
          </el-col>
          <!-- 基本信息跟AI智能识别 -->
          <el-col :span="8" style="padding-left: 45px">
            <div class="content_box">
              <!-- 下拉框 -->
              <el-dropdown
                trigger="click"
                placement="bottom-start"
                @command="handleCommand"
              >
                <span class="el-dropdown-link">
                  {{ selectTitle}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="material_dropdown">
                  <el-dropdown-item
                    command="a"
                    :class="switchContent ? 'dropdown_active' : ''"
                    >基本信息</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    :class="switchContent ? '' : 'dropdown_active'"
                    >AI智能识别</el-dropdown-item
                  >
                </el-dropdown-menu>

              </el-dropdown>
              <!-- 添加新标签 -->
              <div v-if="selectTitle =='AI智能识别' && allowEdit == 1 " style=" display: flex">
                <!-- 输入框 -->
                <el-input
                  v-focus
                  class="input-new-tag"
                  v-if="keyWordsVisible"
                  v-model="keyWordsValue"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm()"
                >
                </el-input>
                <!-- 对号跟错号 -->
                <div class="operation" v-if="keyWordsVisible">
                  <i
                    class="iconfont icon-duihao"
                    @click="handleInputConfirm()"
                  ></i>
                  <i class="iconfont icon-cuo" @click="clearInput"></i>
                </div>
                <!-- 添加新标签 -->
                <el-dropdown
                  placement="bottom-start"
                  trigger="click"
                  @command="addNewLabel"
                  v-else
                >
                  <el-button
                    class="button-new-tag"
                    size="small"
                    type="primary"
                      :style="{background:bgcColor}"
                  >
                    <i class="iconfont icon-jiahao1"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" class="add_dropdown">
                    <el-dropdown-item command="1">添加标签</el-dropdown-item>
                    <el-dropdown-item command="2"
                      >添加关键词</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <div
                  class="existed_tag category_tag"
                  v-if="keyWordsExistedTag"
                >
                  {{ tip }}
                </div>
              </div>

            </div>
             <!-- 基本信息 -->
              <div class="base_info" v-if="switchContent">
                <p class="row_info">
                  <span>编号：</span>
                  <span>{{ videoAndImgData.ip_id | editId }}</span>
                </p>
                <p
                  class="row_info"
                  style="display: flex; align-items: baseline"
                >
                  <span style="flex-shrink: 0">商用权限：</span>
                  <span style="line-height: 20px">{{
                    videoAndImgData.commercial_licenses
                  }}</span>
                </p>
                <p class="row_info">
                  <span>作者/来源：</span>
                  <span>{{ videoAndImgData.uploader }}</span>
                </p>
                <p class="row_info">
                  <span>分辨率：</span>
                  <span>{{ videoAndImgData.resolution }}</span>
                </p>
                <p class="row_info">
                  <span>文件类型：</span>
                  <span>{{ videoAndImgData.format }}</span>
                </p>
                <p class="row_info">
                  <span>文件大小：</span>
                  <span>{{ videoAndImgData.size | transformByte }}</span>
                </p>
                <p class="row_info">
                  <span>创建时间：</span>
                  <span>{{ videoAndImgData.upload_time | formatTime }} </span>
                </p>
              </div>
              <!-- ai智能识别 -->
              <div class="ai_intelligence" v-else>
                <!-- 标签组，关键词 -->
                <div>
                   <!-- 标签组 -->
                  <div
                    class="row_line"
                    v-for="(item, index) in labelGroupData"
                    :key="index"
                  >
                    <span  v-show="!(item.labelData.length==0)" class="category">{{ item.labelGroupName }}</span>
                    <div class="ai_tag">
                      <el-tag
                        :key="tag.tag_id"
                        v-for="tag in item.labelData"
                        :closable="allowEdit?true:false"
                        :disable-transitions="false"
                        @close="handleClose(tag.tag_id,1)"
                      >
                        {{ tag.tag_name }}
                      </el-tag>
                    </div>
                  </div>
                     <!-- 关键词 -->
                  <div class="row_line">
                    <span v-show="!(keyWordsTags.length==0)" class="category">关键词</span>
                    <div class="ai_tag">
                      <el-tag
                        :key="tag.id"
                        v-for="tag in keyWordsTags"
                        :closable="allowEdit?true:false"
                        :disable-transitions="false"
                        @close="handleClose(tag.id, 2)"
                      >
                        {{ tag.keyword }}
                      </el-tag>
                    </div>
                  </div>
                </div>

              </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 对话框 -->
    <el-dialog
      title="下载"
      :visible.sync="downloadDialog"
      width="461px"
      top="30vh"
      custom-class="material-dialog"
      :show-close="true"
    >
      <span style="display: block; margin-bottom: 21px">分辨率：</span>
      <div class="radio_check">
        <el-radio label="标清" v-if="resolutions.length == 1" class="disable_radio">标清</el-radio>
        <el-radio
          v-for="(item, index) in resolutions"
          :key="index"
          v-model="system"
          :label="item.url"
          >{{ item.resolution }}</el-radio
        >
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadConfirm">确 定</el-button>
        <el-button @click="downloadDialog = false" style="margin-right: 30px"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
  <div v-else-if="hasLimits == 1"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    "
  >
    <div style="text-align:center" >
      <i
        style="font-size: 100px; color: rgb(94, 95, 114)"
        class="iconfont icon-meiyouquanxian"
      ></i>
     <div style=" font-weight: bold;font-family: Microsoft YaHei;font-size: 20px; color: rgb(94, 95, 114)" > 您暂时没有浏览权限</div>
    </div>
  </div>
</template>

<script>
// import Player from "xgplayer";
import Xgplayer from "xgplayer-vue";
import '../assets/.xgplayer/skin/index.js'

import $ from "jquery";
export default {
  name: "materialDetail",
  components: {
    Xgplayer,
  },
  data() {
    return {
      videoAndImgData: "", //视频图片的数据
      player: null,
      ifcopy: false, //是否复制
      downloadDialog: false, //显示隐藏下载对话框
      labelGroupData: [], //标签组数据
      keyWordsTags: [], //关键词 -数组
      system: "", //选择的分辨率
      resolutions: [], //下载的分辨率
      keyWordsVisible: false, //加号跟输入框的切换显示
      keyWordsValue: "", // 输入框值
      command: "", //选中的添加标签还是添加关键词
      keyWordsExistedTag: false, //显示隐藏关键词已经存在
      selectTitle: "基本信息",
      switchContent: true,
      tip: "", //提示词
      hasLimits: 3, //控制有无权限查看的隐藏显示
      bgcColor:'',//控制加号点击的背景色
      allowEdit:'',//是否有权限可以修改数据
    };
  },
  mounted() {
    let video_boxW = $(".video_box").width();
    $(".video_box").css("height", video_boxW * 0.587 + "px");
    window.onresize = () => {
      let video_boxW = $(".video_box").width();
      $(".video_box").css("height", video_boxW * 0.587 + "px");
    };
  },
  methods: {
    playInit(e) {
       this.player = e;
       this.monitorPlaying();
      // this.player.emit('resourceReady', [{ name: '超清', url: '//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/mp4/xgplayer-demo-720p.mp4' }, { name: '高清', url: '//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/mp4/xgplayer-demo-480p.mp4' }, { name: '标清', url: '//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/mp4/xgplayer-demo-360p.mp4' }])
    },
    // 监听视频播放状态
    monitorPlaying() {
      this.player.on("playing", () => {
        // 通知小窗播放视频暂停
        this.$store.commit('setplaying',2)
       setTimeout(() => {
          this.$store.commit('setplaying',0)
       }, 0);
      });
    },
    // 分享链接按钮
    copyPath() {
      this.ifcopy = true;
      this.copyUrl();
      setTimeout(() => {
        this.ifcopy = false;
      }, 3000);
    },
    // 添加/取消收藏
    addCollet() {
      let tp = this.$route.query.videoOrimg == "video" ? 1 : 2;
      let formData = new FormData();
      formData.append("ip_id", this.videoAndImgData.ip_id);
      formData.append("type", tp);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_collect",
        data: formData,
      }).then((res) => {
        if (res.data.msg == "收藏成功") {
          this.videoAndImgData.collect_if = 2;
        }
        if (res.data.msg == "取消收藏成功") {
          this.videoAndImgData.collect_if = 0;
        }
      }).catch(error => console.log(error));
    },
    // 下载按钮
    videoDownloadConfirm() {
      // 如果是图片
      if (this.$route.query.videoOrimg == "img") {
        this.downloadImg(this.videoAndImgData.url, this.videoAndImgData.name);
      }
      // 如果是视频
      if (this.$route.query.videoOrimg == "video") {
        let formData = new FormData();
        formData.append("ip_id", this.videoAndImgData.ip_id);
        this.$http({
          url: "/upload/get_download_res",
          method: "POST",
          data: formData,
        }).then((res) => {
          // console.log(res.data);
          if (res.data.status == 0) {
            this.resolutions = res.data.result;
            this.system = res.data.result[0].url;
            // 弹出下载框
            this.downloadDialog = true;
          }
        }).catch(error => console.log(error));
      }
    },
    // 下载框确认按钮
    downloadConfirm() {
     // 切换视频配置源 然后视频下载。
      let link = document.createElement('a');
      link.href = this.system  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
      this.downloadDialog = false;
    },
    // 下载图片 传入下载地址
    downloadImg(imgsrc, name) {
        let download_name = ''
      let link = document.createElement('a');
      link.download = download_name  // 下载文件的名字
      // console.log(download_name)
      link.href = imgsrc  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
    },
    // 加入观看列表事件
    addWatchList(id) {
      let formData = new FormData();
      formData.append("ip_id", this.videoAndImgData.ip_id);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_watch_record",
        data: formData,
      }).then((res) => {
        // 加入成功
        if (res.data.status == 0) {
          this.$store.commit("editAdd", true);
          setTimeout(() => {
            this.$store.commit("editAdd", false);
          }, 2000);
        }
      }).catch(error => console.log(error));
    },
    //删除关键词
    handleClose(del_id, del_type) {
      let params = JSON.stringify({
        ip_id: this.$route.query.ip_id + "",
        del_type: del_type + "",
        del_id: del_id + "",
      });
      // console.log(params)
      this.$http({
        url: "vw_material/update_ip_tag_and_keyword",
        method: "DELETE",
        data: params,
      }).then((res) => {
        if(res.data.status==0){
          // console.log(res.data)
             this.getLabelData();
        }
      }).catch(error => console.log(error));
    },
    // 错号按钮
    clearInput() {
      this.bgcColor=''
      this.keyWordsValue = "";
      this.keyWordsVisible = false;
      this.keyWordsExistedTag = false;
    },
    // 对号按钮
    handleInputConfirm() {
      this.bgcColor=''
      let str = this.keyWordsValue.trim();
      // 如果是空格，
      if (str.length == 0) {
        this.keyWordsExistedTag = true;
        this.tip = "输入为空";
        setTimeout(() => {
          this.keyWordsExistedTag = false;
        }, 3000);
      }
      //不允许首尾有空格
      else {
        let params = JSON.stringify({
          ip_id: this.$route.query.ip_id + "",
          add_type: this.command + "",
          add_name: str + "",
        });
        this.$http({
          url: "vw_material/update_ip_tag_and_keyword",
          method: "POST",
          data: params,
        }).then((res) => {
          // console.log(res.data);
          // 添加成功重新获取标签跟关键词数据
          if (res.data.status == 0) {
            this.keyWordsValue=''
            this.getLabelData();
          }
          // 关键词已经存在
          else {
            this.getLabelData();
            this.keyWordsExistedTag = true;
            this.tip = res.data.msg;
            setTimeout(() => {
              this.keyWordsExistedTag = false;
            }, 3000);
          }
        }).catch(error => console.log(error));
      }
    },
    showInput(num) {
      switch (num) {
        case 0:
          this.sceneVisible = true;
          this.$nextTick((_) => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
          break;
        case 1:
          this.characterVisible = true;
          this.$nextTick((_) => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
          break;
        case 2:
          this.objectVisible = true;
          this.$nextTick((_) => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
          break;
        case 3:
          this.keyWordsVisible = true;
          this.$nextTick((_) => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
          break;
        default:
          break;
      }
    },
    // 复制链接事件
    copyUrl() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = encodeURI(this.videoAndImgData.copy_url); // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    },
    //基本信息跟AI智能切换
    handleCommand(command) {
      if (command == "a") {
        this.selectTitle = "基本信息";
        this.switchContent = true;
      } else if (command == "b") {
           this.selectTitle = "AI智能识别";
           this.switchContent = false;
           // 请求标签组数据跟关键词数据
           this.getLabelData();
      }
    },
    // 添加标签跟添加关键词的切换
    addNewLabel(command) {
      this.command = command;
      // 显示对话框
      this.keyWordsVisible = true;
    },
    // 请求素材详情
    getDetail() {
      this.$http({
        url: "vw_material/get_ip_info",
        method: "GET",
        params: { ip_id: this.$route.query.ip_id },
      }).then((res) => {
        // 无权限查看
        if (res.data.status == 1) {
           this.hasLimits = 1;
        }
        // // 有权限查看
        if (res.data.status == 0) {
           this.hasLimits = 0;
           this.videoAndImgData = res.data.result;
           this.allowEdit = res.data.result.tag_allow_change_if
        }

      }).catch(error => console.log(error));
    },
    // 请求标签组数据跟关键词数据
    getLabelData() {
      this.$http({
        url: "vw_material/update_ip_tag_and_keyword",
        method: "GET",
        params: { ip_id: this.videoAndImgData.ip_id },
      }).then((res) => {
        // 关键词

        this.keyWordsTags = res.data.keyword_list;
        // 将标签组数据进行修改
        this.editLabelData(res.data.tag_group_dict);
      }).catch(error => console.log(error));
    },
    // 点击加号 将背景颜色改变
    showorhidden(e){
      if(e){
        this.bgcColor='#3e3d4c'
      }
      else{
        this.bgcColor=''
      }

    },
    // 将标签组数据进行修改
    editLabelData(labelData) {
      let newarr = [];
      for (let key in labelData) {
        let newitem = {};
        newitem.labelGroupName = key;
        newitem.labelData = labelData[key];
        newarr.push(newitem);
      }
      this.labelGroupData = newarr;
      // console.log(this.labelGroupData[0])
    },
  },
  created() {
    // 请求素材详情
    this.getDetail();
  },
  filters: {
    // 过滤id,将id前边加上0，总长度为8位
    editId: (val) => {
      let addLen = 8 - String(val).length;
      for (let i = 0; i < addLen; i++) {
        val = "0" + val;
      }
      return val;
    },
    // 过滤时间，将时间的T去掉
    formatTime: (val) => {
      let s = String(val).replace("T", " ");
      return s;
    },
  },
  computed: {
    // 视频的配置
    playerConfig: function () {
      return {
        id: "materialDetail",
        videoInit: true,
        cssFullscreen: true,
        poster: "",
        fluid: true,
        download: false,
        closeVideoDblclick: true,
        url: this.videoAndImgData.url,
        playbackRate: [0.5, 0.75, 1, 1.5, 2],
        ignores: ['fullscreen', 'replay'],
        loop: true,
        // definitionActive: 'click'
      };
    },
  },
  watch:{
 // 观看列表小窗的视频是否在播放
     "$store.state.playing": {
      deep: true,
      handler: function (newValue, oldValue) {
        if(newValue==1){
          this.player.pause()
        }

      },
    },
  },
   directives: {
      focus: {
        inserted: function(el) {
          el.querySelector("input").focus();
        }
      }
  },
};
</script>
<style lang="scss" scoped>
.meterial_detail {
  // height: 100vh;
  // overflow-y: auto;
  // 视频详情
  .video_detail {
    padding: 69px 112px 0 65px;
    .top_title {
      span {
        font-size: 18px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.9);
      }
      p {
        font-size: 14px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 8px;
      }
    }
    .material_content {
      margin-top: 10px;
      .existed_tag {
        font-size: 12px;
        background-color: #2a9cff;
        color: #fff;
        border-radius: 2px;
        padding: 5px 9px;
        position: absolute;
        box-sizing: border-box;
      }
      .category_tag {
        min-width: 90px;
        margin-top: -30px;
        margin-left: 9px;
      }
      .video_box {

        display: flex;
        align-items: center;
        position: relative;
        background-color: #000000;
        height: 59.5vh;
        &:hover {
          .hover_effect {
            display: flex;
          }
        }

        }

        .hover_effect {
          position: absolute;
          z-index: 10000;
          right: 14px;
          top: 26px;
          display: none;
          .iconfont {
            padding: 4px;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.5);
            display: inline-block;
            margin-right: 5px;
            cursor: pointer;
          }
          .icon-shoucang {
            color: #ffa200;
          }
        }
      }
      .content_box {
        color: rgba(255, 255, 255, 0.65);
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-dropdown {
          color: rgba(255, 255, 255, 0.9);
          width: 112px;
          height: 36px;
         line-height: 36px;
          .el-dropdown-link {
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-icon-arrow-down {
              font-weight: bold;
              cursor:pointer;
            }
          }
        }
         .button-new-tag {
            margin-left: 9px;
            padding: 0;
            background-color: transparent;
            border: none;
            width: 30px;
            height: 30px;
            .icon-jiahao1 {
              font-size: 18px;
              color: #fff;
              &:hover {
                color: #2a9cff;
              }
            }
          }
          .input-new-tag {
            width: 177px;
            margin-left: 9px;
            vertical-align: bottom;
            border-radius: 6px;
            /deep/.el-input__inner {
              border: 1px solid #5e5f72;
              background-color: transparent;
              color: #fff;
              padding: 0 5px;
               height: 30px;
              line-height: 30px;
            }
          }
          .operation {
            display: inline-block;
            font-size: 20px;
            margin-bottom: 10px;
            .iconfont {
              cursor: pointer;
              font-size: 20px;
              margin-left: 10px;
            }
            .icon-duihao {
              color: #69ba63;
              &:hover {
                color: #5fed55;
              }
            }
            .icon-cuo {
              color: #894141;
              &:hover {
                color: #d54b4b;
              }
            }
          }

      }
      .base_info {
        margin-top: 20px;
        color: rgba(255, 255, 255, 0.65);
          .row_info {
            line-height: 14px;
            margin-bottom: 12px;
            /deep/.el-rate {
              display: inline-block;
              vertical-align: middle;
              .el-rate__icon {
                color: #eec600 !important;
              }
            }
          }
        }
        .ai_intelligence {
          color: rgba(255, 255, 255, 0.65);
          margin-top: 20px;
          display: flex;
          .row_line {
            display: flex;
            align-items: baseline;
            margin-top: 5px;
            .category {
              flex-shrink: 0;
            }
            .ai_tag {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              /deep/.el-tag {
                background-color: #3e3d4c;
                color: rgba(255, 255, 255, 0.8);
                border: inherit;
                border-radius: 6px;
                position: relative;
                margin: 0 9px 9px 9px;
                font-size: 14px;
                .el-tag__close {
                  position: absolute;
                  display: none;
                  &:hover {
                    background-color: inherit;
                    color: #2a9cff !important;
                  }
                }
                &:hover {
                  color: #2a9cff;
                  .el-tag__close {
                    display: inline-block;
                    top: -3px;
                    right: -15px;
                    color: #9c9ca1;
                  }
                }
              }
              // .el-tag + .el-tag{
              //   margin-left: 18px;
              // }
            }
          }

        }
    }
  // 图片详情
  .img_detail {
    padding: 69px 112px 0 65px;
    .top_title {
      span {
        font-size: 18px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.9);
      }
      p {
        font-size: 14px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 8px;
      }
    }
    .material_content {
      margin-top: 10px;
      .existed_tag {
        font-size: 12px;
        background-color: #2a9cff;
        color: #fff;
        border-radius: 2px;
        padding: 5px 9px;
        position: absolute;
        box-sizing: border-box;
      }
      .category_tag {
         min-width: 90px;
        margin-top: -30px;
        right:146px;
      }
      .video_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #000000;
        height: 59.5vh;
         &:hover {
          .hover_effect {
            display: flex;
          }
        }
        .hover_effect {
          position: absolute;
          z-index: 10000;
          right: 14px;
          top: 26px;
          display: none;
          .iconfont {
            padding: 4px;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.5);
            display: inline-block;
            margin-right: 5px;
            cursor: pointer;
          }
          .icon-shoucang {
            color: #ffa200;
          }
        }
      }
      .content_box {
        color: rgba(255, 255, 255, 0.65);
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        .el-dropdown {
          color: rgba(255, 255, 255, 0.9);
          width: 112px;
          height: 36px;
         line-height: 36px;
          .el-dropdown-link {
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-icon-arrow-down {
              font-weight: bold;
            }
          }
        }
         .button-new-tag {
            margin-left: 9px;
            margin-bottom: 10px;
            padding: 0;
            background-color: transparent;
            border: none;
            width: 30px;
    height: 30px;
            .icon-jiahao1 {
              font-size: 18px;
              color: #fff;
              &:hover {
                color: #2a9cff;
              }
            }
          }
          .input-new-tag {
            width: 177px;
            margin-left: 9px;
            vertical-align: bottom;
            border-radius: 6px;
            /deep/.el-input__inner {
              border: 1px solid #5e5f72;
              background-color: transparent;
              color: #fff;
              padding: 0 5px;
              height: 30px;
              line-height: 30px;
            }
          }
          .operation {
            display: inline-block;
            font-size: 20px;
            margin-bottom: 10px;
            .iconfont {
              cursor: pointer;
              font-size: 20px;
              margin-left: 10px;
            }
            .icon-duihao {
              color: #69ba63;
              &:hover {
                color: #5fed55;
              }
            }
            .icon-cuo {
              color: #894141;
              &:hover {
                color: #d54b4b;
              }
            }
          }

      }
      .base_info {
        margin-top: 20px;
        color: rgba(255, 255, 255, 0.65);
          .row_info {
            line-height: 14px;
            margin-bottom: 12px;
            /deep/.el-rate {
              display: inline-block;
              vertical-align: middle;
              .el-rate__icon {
                color: #eec600 !important;
              }
            }
          }
        }
        .ai_intelligence {
          color: rgba(255, 255, 255, 0.65);
          margin-top: 20px;
          display: flex;
          .row_line {
            display: flex;
            align-items: baseline;
            margin-top:5px;
            .category {
              flex-shrink: 0;
            }
            .ai_tag {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              /deep/.el-tag {
                background-color: #3e3d4c;
                color: rgba(255, 255, 255, 0.8);
                border: inherit;
                border-radius: 6px;
                position: relative;
                margin: 0 9px 9px 9px;
                .el-tag__close {
                  position: absolute;
                  display: none;
                  &:hover {
                    background-color: inherit;
                    color: #2a9cff !important;
                  }
                }
                &:hover {
                  color: #2a9cff;
                  .el-tag__close {
                    display: inline-block;
                    top: -3px;
                    right: -15px;
                    color: #9c9ca1;
                  }
                }
              }
              // .el-tag + .el-tag{
              //   margin-left: 18px;
              // }
            }
          }

        }
    }
  }
}
.material_dropdown {
  margin-top: 6px;
  margin-left: -8px;
  padding: 0;
  background-color: #3e3d4c;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 5px;
  .el-dropdown-menu__item {
    padding: 0 24px 0 8px;
    line-height: 38px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 18px;
    &:hover {
      background-color: inherit;
      color: #2a9cff;
    }
  }
  .dropdown_active {
    color: #2a9cff;
  }
  /deep/.popper__arrow {
    display: none;
  }
}
.add_dropdown {
  /deep/.button-new-tag{
  }
  position: absolute;
  margin-top: 2px;
    margin-left: -49px;
  padding: 0;
  background-color: #3e3d4c;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 5px;
  text-align: right;
  .el-dropdown-menu__item {
    padding: 0 10px 0 8px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    &:hover {
      background-color: inherit;
      color: #2a9cff;
    }
  }
  .dropdown_active {
    color: #2a9cff;
  }
  /deep/.popper__arrow {
    display: none;
  }
}
</style>

<style lang="scss">
.el-dialog.material-dialog {
  background-color: #3e3d4c;
  border-radius: 15px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  padding: 18px 21px 21px;
  .el-dialog__header {
    text-align: center;
    padding: 0;
    padding-bottom: 13px;
    .el-dialog__title {
      color: #fff;
      font-size: 16px;
      letter-spacing: 30px;
    }
    .el-dialog__headerbtn {
      .el-dialog__close {
        color: #fff;
        font-size: 18px;
      }
    }
  }
  .el-dialog__body {
    color: #fff;
    background-color: #2d2c39;
    font-size: 14px;
    padding: 38px 69px 0 69px;
    .radio_check {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
       .disable_radio{
            .el-radio__inner{
              background-color: rgba(255,255,255,0.5);
              border: inherit;
            }
            .el-radio__label{
              color: rgba(255,255,255,0.5);
            }
          }
      .el-radio {
        width: 50%;
        margin-right: 0;
        margin-bottom: 18px;
        color: #fff;
      }
      .el-radio__inner {
        width: 16px;
        height: 16px;
      }
      .is-checked {
        .el-radio__inner {
          width: 16px;
          height: 16px;
          border: 3px solid #fff;
          &::after {
            background-color: inherit;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    padding: 20px 69px 45px 69px;
    background-color: #2d2c39;
    border-radius: 0px 0px 10px 10px;
    .dialog-footer {
      display: flex;
      justify-content: space-between;
      .el-button {
        border-radius: 20px;
        border: 1px solid #5e5f72;
        background-color: inherit;
        color: #fff;
        padding: 6px 15px;
      }
    }
  }
}
</style>
