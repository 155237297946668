var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLimits == 0
    ? _c(
        "div",
        { staticClass: "meterial_detail" },
        [
          _c("div", [
            (_vm.$route.query.videoOrimg == "video"
            ? true
            : false)
              ? _c(
                  "div",
                  { staticClass: "video_detail" },
                  [
                    _c(
                      "el-row",
                      { staticClass: "top_title", attrs: { type: "flex" } },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-right": "69px" },
                            attrs: { span: 16 }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.videoAndImgData.name))
                            ]),
                            _c("p", [
                              _vm._v(_vm._s(_vm.videoAndImgData.introduce))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticClass: "material_content",
                        attrs: { type: "flex" }
                      },
                      [
                        _c("el-col", { attrs: { span: 16 } }, [
                          _c(
                            "div",
                            { staticClass: "video_box" },
                            [
                              _c("Xgplayer", {
                                staticClass: "xgplayer-is-fullscreen",
                                attrs: {
                                  id: "materialDetail",
                                  config: _vm.playerConfig
                                },
                                on: {
                                  player: function($event) {
                                    return _vm.playInit($event)
                                  }
                                }
                              }),
                              _c("div", { staticClass: "hover_effect" }, [
                                _c("span", {
                                  staticClass: "iconfont icon-fenxiang",
                                  on: { click: _vm.copyPath }
                                }),
                                _c("span", {
                                  staticClass: "iconfont icon-xiazai",
                                  on: { click: _vm.videoDownloadConfirm }
                                }),
                                _c("span", {
                                  staticClass:
                                    "iconfont icon-tianjiazhiguankanliebiao",
                                  on: {
                                    click: function($event) {
                                      return _vm.addWatchList()
                                    }
                                  }
                                }),
                                (_vm.videoAndImgData.collect_if == 0
                                ? true
                                : false)
                                  ? _c("span", {
                                      staticClass: "iconfont icon-weishoucang",
                                      on: { click: _vm.addCollet }
                                    })
                                  : _c("span", {
                                      staticClass: "iconfont icon-shoucang",
                                      on: { click: _vm.addCollet }
                                    }),
                                _vm.ifcopy
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "existed_tag",
                                        staticStyle: {
                                          top: "-37px",
                                          left: "-28%",
                                          "letter-spacing": "2px"
                                        }
                                      },
                                      [_vm._v(" 已复制链接 ")]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        ]),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "padding-left": "45px" },
                            attrs: { span: 8 }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "content_box" },
                              [
                                _c(
                                  "el-dropdown",
                                  {
                                    attrs: {
                                      trigger: "click",
                                      placement: "bottom-start"
                                    },
                                    on: { command: _vm.handleCommand }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [
                                        _vm._v(" " + _vm._s(_vm.selectTitle)),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        staticClass: "material_dropdown",
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class: _vm.switchContent
                                              ? "dropdown_active"
                                              : "",
                                            attrs: { command: "a" }
                                          },
                                          [_vm._v(" 基本信息 ")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class: _vm.switchContent
                                              ? ""
                                              : "dropdown_active",
                                            attrs: { command: "b" }
                                          },
                                          [_vm._v("AI智能识别")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.selectTitle == "AI智能识别" &&
                                _vm.allowEdit == 1
                                  ? _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _vm.keyWordsVisible
                                          ? _c("el-input", {
                                              directives: [
                                                {
                                                  name: "focus",
                                                  rawName: "v-focus"
                                                }
                                              ],
                                              ref: "saveTagInput",
                                              staticClass: "input-new-tag",
                                              attrs: { size: "small" },
                                              nativeOn: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.handleInputConfirm()
                                                }
                                              },
                                              model: {
                                                value: _vm.keyWordsValue,
                                                callback: function($$v) {
                                                  _vm.keyWordsValue = $$v
                                                },
                                                expression: "keyWordsValue"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.keyWordsVisible
                                          ? _c(
                                              "div",
                                              { staticClass: "operation" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont icon-duihao",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleInputConfirm()
                                                    }
                                                  }
                                                }),
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont icon-cuo",
                                                  on: { click: _vm.clearInput }
                                                })
                                              ]
                                            )
                                          : _c(
                                              "el-dropdown",
                                              {
                                                attrs: {
                                                  placement: "bottom-start",
                                                  trigger: "click"
                                                },
                                                on: {
                                                  command: _vm.addNewLabel,
                                                  "visible-change":
                                                    _vm.showorhidden
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass:
                                                      "button-new-tag",
                                                    style: {
                                                      background: _vm.bgcColor
                                                    },
                                                    attrs: {
                                                      size: "small",
                                                      type: "primary"
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "iconfont icon-jiahao1"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "el-dropdown-menu",
                                                  {
                                                    staticClass: "add_dropdown",
                                                    attrs: { slot: "dropdown" },
                                                    slot: "dropdown"
                                                  },
                                                  [
                                                    _c(
                                                      "el-dropdown-item",
                                                      {
                                                        attrs: { command: "1" }
                                                      },
                                                      [_vm._v("添加标签")]
                                                    ),
                                                    _c(
                                                      "el-dropdown-item",
                                                      {
                                                        attrs: { command: "2" }
                                                      },
                                                      [_vm._v("添加关键词")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                        _vm.keyWordsExistedTag
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "existed_tag category_tag"
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(_vm.tip) + " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.switchContent
                              ? _c("div", { staticClass: "base_info" }, [
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("编号：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("editId")(
                                            _vm.videoAndImgData.ip_id
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "row_info",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "baseline"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { "flex-shrink": "0" } },
                                        [_vm._v("商用权限：")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "line-height": "20px" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.videoAndImgData
                                                .commercial_licenses
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("作者/来源：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.videoAndImgData.uploader)
                                      )
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("时长：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.videoAndImgData.duration)
                                      )
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("分辨率：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.videoAndImgData.resolution)
                                      )
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("帧率：")]),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.videoAndImgData.frame))
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("编码：")]),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.videoAndImgData.coding))
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("文件类型：")]),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.videoAndImgData.format))
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("文件大小：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("transformByte")(
                                            _vm.videoAndImgData.size
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("创建时间：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatTime")(
                                            _vm.videoAndImgData.upload_time
                                          )
                                        ) + " "
                                      )
                                    ])
                                  ])
                                ])
                              : _c("div", { staticClass: "ai_intelligence" }, [
                                  _c(
                                    "div",
                                    [
                                      _vm._l(_vm.labelGroupData, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "row_line"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !(
                                                      item.labelData.length == 0
                                                    ),
                                                    expression:
                                                      "!(item.labelData.length==0)"
                                                  }
                                                ],
                                                staticClass: "category"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.labelGroupName)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "ai_tag" },
                                              _vm._l(item.labelData, function(
                                                tag
                                              ) {
                                                return _c(
                                                  "el-tag",
                                                  {
                                                    key: tag.id,
                                                    attrs: {
                                                      closable: _vm.allowEdit
                                                        ? true
                                                        : false,
                                                      "disable-transitions": false
                                                    },
                                                    on: {
                                                      close: function($event) {
                                                        return _vm.handleClose(
                                                          tag.tag_id,
                                                          1
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(tag.tag_name) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }),
                                              1
                                            )
                                          ]
                                        )
                                      }),
                                      _c("div", { staticClass: "row_line" }, [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !(
                                                  _vm.keyWordsTags.length == 0
                                                ),
                                                expression:
                                                  "!(keyWordsTags.length==0)"
                                              }
                                            ],
                                            staticClass: "category"
                                          },
                                          [_vm._v("关键词")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "ai_tag" },
                                          _vm._l(_vm.keyWordsTags, function(
                                            tag
                                          ) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: tag.id,
                                                attrs: {
                                                  closable: _vm.allowEdit
                                                    ? true
                                                    : false,
                                                  "disable-transitions": false
                                                },
                                                on: {
                                                  close: function($event) {
                                                    return _vm.handleClose(
                                                      tag.id,
                                                      2
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(tag.keyword) +
                                                    " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "img_detail" },
                  [
                    _c(
                      "el-row",
                      { staticClass: "top_title", attrs: { type: "flex" } },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-right": "69px" },
                            attrs: { span: 16 }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.videoAndImgData.name))
                            ]),
                            _c("p", [
                              _vm._v(_vm._s(_vm.videoAndImgData.introduce))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticClass: "material_content",
                        attrs: { type: "flex" }
                      },
                      [
                        _c("el-col", { attrs: { span: 16 } }, [
                          _c("div", { staticClass: "video_box" }, [
                            _c("img", {
                              staticStyle: {
                                "max-width": "100%",
                                "max-height": "100%",
                                "flex-shrink": "0"
                              },
                              attrs: { src: _vm.videoAndImgData.url, alt: "" }
                            }),
                            _c("div", { staticClass: "hover_effect" }, [
                              _c("span", {
                                staticClass: "iconfont icon-fenxiang",
                                on: { click: _vm.copyPath }
                              }),
                              _c("span", {
                                staticClass: "iconfont icon-xiazai",
                                on: { click: _vm.videoDownloadConfirm }
                              }),
                              (_vm.videoAndImgData.collect_if == 0
                              ? true
                              : false)
                                ? _c("span", {
                                    staticClass: "iconfont icon-weishoucang",
                                    on: { click: _vm.addCollet }
                                  })
                                : _c("span", {
                                    staticClass: "iconfont icon-shoucang",
                                    on: { click: _vm.addCollet }
                                  }),
                              _vm.ifcopy
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "existed_tag",
                                      staticStyle: {
                                        top: "-37px",
                                        left: "-28%",
                                        "letter-spacing": "2px"
                                      }
                                    },
                                    [_vm._v(" 已复制链接 ")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "padding-left": "45px" },
                            attrs: { span: 8 }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "content_box" },
                              [
                                _c(
                                  "el-dropdown",
                                  {
                                    attrs: {
                                      trigger: "click",
                                      placement: "bottom-start"
                                    },
                                    on: { command: _vm.handleCommand }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [
                                        _vm._v(" " + _vm._s(_vm.selectTitle)),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        staticClass: "material_dropdown",
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class: _vm.switchContent
                                              ? "dropdown_active"
                                              : "",
                                            attrs: { command: "a" }
                                          },
                                          [_vm._v("基本信息")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class: _vm.switchContent
                                              ? ""
                                              : "dropdown_active",
                                            attrs: { command: "b" }
                                          },
                                          [_vm._v("AI智能识别")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.selectTitle == "AI智能识别" &&
                                _vm.allowEdit == 1
                                  ? _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _vm.keyWordsVisible
                                          ? _c("el-input", {
                                              directives: [
                                                {
                                                  name: "focus",
                                                  rawName: "v-focus"
                                                }
                                              ],
                                              ref: "saveTagInput",
                                              staticClass: "input-new-tag",
                                              attrs: { size: "small" },
                                              nativeOn: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.handleInputConfirm()
                                                }
                                              },
                                              model: {
                                                value: _vm.keyWordsValue,
                                                callback: function($$v) {
                                                  _vm.keyWordsValue = $$v
                                                },
                                                expression: "keyWordsValue"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.keyWordsVisible
                                          ? _c(
                                              "div",
                                              { staticClass: "operation" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont icon-duihao",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleInputConfirm()
                                                    }
                                                  }
                                                }),
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont icon-cuo",
                                                  on: { click: _vm.clearInput }
                                                })
                                              ]
                                            )
                                          : _c(
                                              "el-dropdown",
                                              {
                                                attrs: {
                                                  placement: "bottom-start",
                                                  trigger: "click"
                                                },
                                                on: { command: _vm.addNewLabel }
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass:
                                                      "button-new-tag",
                                                    style: {
                                                      background: _vm.bgcColor
                                                    },
                                                    attrs: {
                                                      size: "small",
                                                      type: "primary"
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "iconfont icon-jiahao1"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "el-dropdown-menu",
                                                  {
                                                    staticClass: "add_dropdown",
                                                    attrs: { slot: "dropdown" },
                                                    slot: "dropdown"
                                                  },
                                                  [
                                                    _c(
                                                      "el-dropdown-item",
                                                      {
                                                        attrs: { command: "1" }
                                                      },
                                                      [_vm._v("添加标签")]
                                                    ),
                                                    _c(
                                                      "el-dropdown-item",
                                                      {
                                                        attrs: { command: "2" }
                                                      },
                                                      [_vm._v("添加关键词")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                        _vm.keyWordsExistedTag
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "existed_tag category_tag"
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(_vm.tip) + " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.switchContent
                              ? _c("div", { staticClass: "base_info" }, [
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("编号：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("editId")(
                                            _vm.videoAndImgData.ip_id
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "row_info",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "baseline"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { "flex-shrink": "0" } },
                                        [_vm._v("商用权限：")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "line-height": "20px" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.videoAndImgData
                                                .commercial_licenses
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("作者/来源：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.videoAndImgData.uploader)
                                      )
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("分辨率：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.videoAndImgData.resolution)
                                      )
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("文件类型：")]),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.videoAndImgData.format))
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("文件大小：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("transformByte")(
                                            _vm.videoAndImgData.size
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("p", { staticClass: "row_info" }, [
                                    _c("span", [_vm._v("创建时间：")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatTime")(
                                            _vm.videoAndImgData.upload_time
                                          )
                                        ) + " "
                                      )
                                    ])
                                  ])
                                ])
                              : _c("div", { staticClass: "ai_intelligence" }, [
                                  _c(
                                    "div",
                                    [
                                      _vm._l(_vm.labelGroupData, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "row_line"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !(
                                                      item.labelData.length == 0
                                                    ),
                                                    expression:
                                                      "!(item.labelData.length==0)"
                                                  }
                                                ],
                                                staticClass: "category"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.labelGroupName)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "ai_tag" },
                                              _vm._l(item.labelData, function(
                                                tag
                                              ) {
                                                return _c(
                                                  "el-tag",
                                                  {
                                                    key: tag.tag_id,
                                                    attrs: {
                                                      closable: _vm.allowEdit
                                                        ? true
                                                        : false,
                                                      "disable-transitions": false
                                                    },
                                                    on: {
                                                      close: function($event) {
                                                        return _vm.handleClose(
                                                          tag.tag_id,
                                                          1
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(tag.tag_name) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }),
                                              1
                                            )
                                          ]
                                        )
                                      }),
                                      _c("div", { staticClass: "row_line" }, [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !(
                                                  _vm.keyWordsTags.length == 0
                                                ),
                                                expression:
                                                  "!(keyWordsTags.length==0)"
                                              }
                                            ],
                                            staticClass: "category"
                                          },
                                          [_vm._v("关键词")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "ai_tag" },
                                          _vm._l(_vm.keyWordsTags, function(
                                            tag
                                          ) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: tag.id,
                                                attrs: {
                                                  closable: _vm.allowEdit
                                                    ? true
                                                    : false,
                                                  "disable-transitions": false
                                                },
                                                on: {
                                                  close: function($event) {
                                                    return _vm.handleClose(
                                                      tag.id,
                                                      2
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(tag.keyword) +
                                                    " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ]),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "下载",
                visible: _vm.downloadDialog,
                width: "461px",
                top: "30vh",
                "custom-class": "material-dialog",
                "show-close": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.downloadDialog = $event
                }
              }
            },
            [
              _c(
                "span",
                { staticStyle: { display: "block", "margin-bottom": "21px" } },
                [_vm._v("分辨率：")]
              ),
              _c(
                "div",
                { staticClass: "radio_check" },
                [
                  _vm.resolutions.length == 1
                    ? _c(
                        "el-radio",
                        {
                          staticClass: "disable_radio",
                          attrs: { label: "标清" }
                        },
                        [_vm._v("标清")]
                      )
                    : _vm._e(),
                  _vm._l(_vm.resolutions, function(item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        attrs: { label: item.url },
                        model: {
                          value: _vm.system,
                          callback: function($$v) {
                            _vm.system = $$v
                          },
                          expression: "system"
                        }
                      },
                      [_vm._v(_vm._s(item.resolution))]
                    )
                  })
                ],
                2
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.downloadConfirm }
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "30px" },
                      on: {
                        click: function($event) {
                          _vm.downloadDialog = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm.hasLimits == 1
    ? _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
            height: "100%"
          }
        },
        [_vm._m(0)]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("i", {
        staticClass: "iconfont icon-meiyouquanxian",
        staticStyle: { "font-size": "100px", color: "rgb(94, 95, 114)" }
      }),
      _c(
        "div",
        {
          staticStyle: {
            "font-weight": "bold",
            "font-family": "Microsoft YaHei",
            "font-size": "20px",
            color: "rgb(94, 95, 114)"
          }
        },
        [_vm._v(" 您暂时没有浏览权限")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }